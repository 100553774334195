<template>
	<footer class="footer">
		<div class="container is-fullwidth has-text-centered is-size-7">
			<a :class="{ 'text-primary': $root.$i18n.locale === 'ro' }" href="#" @click.prevent="changeLanguage('ro')">Romana</a> &middot;
			<a :class="{ 'text-primary': $root.$i18n.locale === 'en' }" href="#" @click.prevent="changeLanguage('en')">English</a>
			<p>&copy; 2021 GeoData Services SRL. {{ $t('All rights reserved.') }}</p>
		</div>
	</footer>
</template>

<script>
export default {
	name: "FooterSection",
	methods: {
		changeLanguage(lang) {
			this.$root.$i18n.locale = lang
			this.$store.dispatch('setLanguage', lang)
		}
	}
}
</script>

<style scoped>

</style>
