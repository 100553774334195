<template>
    <div id="app">
        <component :is="layout">
            <router-view/>
        </component>
    </div>
</template>

<script>
import DefaultLayout from "@/layouts/DefaultLayout"
import {App} from '@capacitor/app'
import {Device} from '@capacitor/device'

export default {
    name: 'App',
    computed: {
        layout() {
            return this.$route.meta.layout || DefaultLayout
        }
    },
    data() {
        return {
            device: null
        }
    },
    mounted() {
        this.getDeviceInfo()
        // Android only
        App.addListener('backButton', () => {
            window.history.back()
        })
    },
    methods: {
        async getDeviceInfo() {
            this.device = await Device.getInfo()
            this.$store.commit('SET_COMMON_DATA', {
                ...this.$store.state.common,
                device: this.device
            })
            document.body.classList.add(this.$store.state.common.device.platform)
        }
    }
}
</script>

<style lang="scss">
@import "assets/styles/app";
</style>
