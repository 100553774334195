<template>
	<header>
		<b-navbar fixed-top type="is-link">
			<template #brand>
				<b-navbar-item tag="router-link" to="/">
					<img alt="GIS ANCPI" src="@/assets/logo.svg">
				</b-navbar-item>
			</template>
			<template #start>
				<b-navbar-item tag="router-link" to="/">
					<b-icon icon="home"/>
					{{ $t('Home') }}
				</b-navbar-item>
				<b-navbar-item tag="router-link" to="/task/viewTasks">
					<b-icon icon="file-tree"/>
					{{ $t('Tasks') }}
				</b-navbar-item>
				<b-navbar-item tag="router-link" to="/task/createTask">
					<b-icon icon="calendar-check"/>
					{{ $t('Add Task') }}
				</b-navbar-item>
				<b-navbar-item tag="router-link" to="/county/viewCounties">
					<b-icon icon="map-search"/>
					{{ $t('View Counties') }}
				</b-navbar-item>
				<b-navbar-item tag="router-link" to="/city/viewCities">
					<b-icon icon="map-search"/>
					{{ $t('View Cities') }}
				</b-navbar-item>				
			</template>

			<template #end>
				<b-navbar-item tag="div">
				</b-navbar-item>
				<b-navbar-item tag="div">
					<div class="buttons">
						<a class="button is-danger" @click="signOut">
							<b-icon icon="lock"/>
							{{ $t('Sign out') }}
						</a>
					</div>
				</b-navbar-item>
			</template>
		</b-navbar>
	</header>
</template>

<script>
export default {
	name: "MainMenu",
	data() {
		return {
			cityModule: null
		}
	},
	mounted() {
		this.cityModule = this.$store.state.selectedCityModule
	},
	methods: {
		signOut() {
			this.$store.dispatch('signOut').then(response => {
				console.log(response);
				this.$router.push('/sign-in')
			}).catch(error => {
				console.log(error);
				this.$router.push('/sign-in')
			})
		},
		setCityModule() {
			this.$store.dispatch('setCityModule', this.cityModule)
		}
	}
}
</script>

<style scoped>

</style>
