<template>
	<div class="default-layout">
		<section class="section hero is-fullheight">
			<div class="hero-body">
				<router-view/>
			</div>
		</section>
	</div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
	name: "DefaultLayout",
	computed: {
		...mapGetters({
			user: 'GET_USER'
		})
	},
	watch: {
		user() {
			if (this.user) {
				this.$router.push('/')
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.section {
	padding: 3rem 5px;
}

.hero-body {
	padding: 0;
}
</style>
