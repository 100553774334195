<template>
	<div class="authenticated-layout has-navbar-fixed-top is-flex is-flex-direction-column">
		<MainMenu v-if="settings.device.platform === 'web'"/>
		<section id="main">
			<router-view/>
		</section>
		<FooterSection v-if="settings.device.platform === 'web'"/>
		<div v-if="pageIsScrolled" v-show="settings.device.platform === 'web'" class="scroll-to-top" @click="$scrollToTop">
			<b-icon icon="chevron-up"></b-icon>
		</div>
	</div>
</template>

<script>
import {mapGetters} from "vuex";
import Vue from "vue";
import MainMenu from "@/components/layout/MainMenu";
import FooterSection from "@/components/layout/FooterSection";
import {Device} from "@capacitor/device";

export default {
	name: "AuthenticatedLayout",
	components: {FooterSection, MainMenu},
	computed: {
		...mapGetters({
			user: 'GET_USER',
			settings: 'GET_COMMON_DATA'
		})
	},
	data() {
		return {
			pageIsScrolled: false
		}
	},
	created() {
		window.addEventListener('scroll', this.handleScroll)
		if (this.user) {
			Vue.axios.defaults.headers.common['x-access-token'] = this.user.token
		}
	},
	mounted() {
		document.body.classList.add('authenticated')
		this.getDeviceInfo()
	},
	destroyed() {
		window.removeEventListener('scroll', this.handleScroll)
		document.body.classList.remove('authenticated')
	},
	methods: {
		handleScroll() {
			this.pageIsScrolled = window.scrollY > 100
		},
		async getDeviceInfo() {
			this.device = await Device.getInfo()
			this.$store.commit('SET_COMMON_DATA', {
				...this.$store.state.common,
				device: this.device
			})
			document.body.classList.add(this.$store.state.common.device.platform)
		},
	}
}
</script>

<style lang="scss">

</style>
