import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VueAxios from 'vue-axios'
import {config} from '../../config/config'
import VuexPersist from 'vuex-persist'

Vue.use(Vuex)

Vue.use(VueAxios, axios.create({
	baseURL: config.API_URL
}))

const vuexLocalStorage = new VuexPersist({
	key: 'vuex',
	storage: window.localStorage
})

export default new Vuex.Store({
	state: {
		language: 'ro',
		user: null,
		redirectUrl: null,
		common: {
			device: {
				platform: 'web'
			}
		},
		selectedCityModule: null
	},
	getters: {
		GET_LANGUAGE(state) {
			return state.language
		},
		GET_USER(state) {
			return state.user
		},
		GET_REDIRECT_URL(state) {
			return state.redirectUrl
		},
		GET_COMMON_DATA(state) {
			return state.common
		},
		GET_CITY_MODULE(state) {
			return state.selectedCityModule
		},
		GET_STATE(state) {
			return state.selectedCityModule
		}
	},
	mutations: {
		SET_LANGUAGE(state, data) {
			state.language = data
		},
		SET_USER(state, data) {
			state.user = data
		},
		SET_REDIRECT_URL(state, data) {
			state.redirectUrl = data
		},
		SET_COMMON_DATA(state, data) {
			state.common = data
		},
		SET_CITY_MODULE(state, data) {
			state.selectedCityModule = data
		},
		SET_STATE(state, data) {
			for (let key in data) {
				Vue.set(state, key, data[key])
			}
		}
	},
	actions: {
		setLanguage({commit}, data) {
			commit('SET_LANGUAGE', data)
		},
		setCityModule({commit}, data) {
			commit('SET_CITY_MODULE', data)
		},
		signIn: async ({commit, dispatch}, data) => {
			return new Promise((resolve, reject) => {
				Vue.axios.post('users/login', data)
					.then(response => {
						commit('SET_USER', response.data)
						Vue.axios.defaults.headers.common['x-access-token'] = response.data.token
						resolve(response)
					})
					.catch(error => {
						reject(error)
						dispatch('signOut')
						Vue.axios.defaults.headers.common['x-access-token'] = ''
					})
			})
		},
		createTask: async ({dispatch}, data) => {
			return new Promise((resolve, reject) => {
				Vue.axios.post('tasks/create', data)
					.then(response => {
						resolve(response)
					})
					.catch(error => {
						reject(error)
						dispatch('signOut')
						Vue.axios.defaults.headers.common['x-access-token'] = ''
					})
			})
		},
		getAdministrativeNumbers: async ({dispatch}, data) => {
			return new Promise((resolve, reject) => {
				Vue.axios.post('tasks/get-administrative-numbers', data)
					.then(response => {
						resolve(response)
					})
					.catch(error => {
						reject(error)
						dispatch('signOut')
						Vue.axios.defaults.headers.common['x-access-token'] = ''
					})
			})
		},
		signOut: async ({commit}) => {
			return new Promise((resolve, reject) => {
				Vue.axios.post('user/logout')
					.then(response => {
						resolve(response)
						commit('SET_USER', null)
						commit('SET_COMMON_DATA', {
							device: {
								platform: 'web'
							}
						})
						Vue.axios.defaults.headers.common['x-access-token'] = ''
					})
					.catch(error => {
						reject(error)
						commit('SET_USER', null)
						commit('SET_COMMON_DATA', {
							device: {
								platform: 'web'
							}
						})
						Vue.axios.defaults.headers.common['x-access-token'] = ''
					})
			})
		},
		getTasksForUser: async ({dispatch}, data) => {
			return new Promise((resolve, reject) => {
				Vue.axios.get('tasks/get-all', {
					params: data
				})
					.then(response => {
						resolve(response)
					})
					.catch(error => {
						if (/401|403/.test(error.response.status)) {
							dispatch('signOut')
						}
						reject(error)
					})
			})
		},
		changeStatus: async ({dispatch}, data) => {
			return new Promise((resolve, reject) => {
				Vue.axios.put('tasks/change-status/'+data.taskId ,
				{
                  newStatus:data.newStatus
				})
					.then(response => {
						resolve(response)
					})
					.catch(error => {
						if (/401|403/.test(error.response.status)) {
							dispatch('signOut')
						}
						reject(error)
					})
			})
		},
		processTasks: async ({dispatch}) => {
			return new Promise((resolve, reject) => {
				Vue.axios.get('tasks/process-tasks')
					.then(response => {
						resolve(response)
					})
					.catch(error => {
						if (/401|403/.test(error.response.status)) {
							dispatch('signOut')
						}
						reject(error)
					})
			})
		},
		getTaskDetails: async ({dispatch}, data) => {
			return new Promise((resolve, reject) => {
				Vue.axios.get('tasks/task-details/'+ data)
					.then(response => {
						resolve(response)
					})
					.catch(error => {
						if (/401|403/.test(error.response.status)) {
							dispatch('signOut')
						}
						reject(error)
					})
			})
		},
		downloadTaskDetails: async ({dispatch}, data) => {
			return new Promise((resolve, reject) => {
				Vue.axios.get('tasks/task-details/export/'+ data, {
					params: data,
					responseType: 'blob'
				})
					.then(response => {
						resolve(response)
					})
					.catch(error => {
						if (/401|403/.test(error.response.status)) {
							dispatch('signOut')
						}
						reject(error)
					})
			})
		}
	},
	modules: {},
	plugins: [vuexLocalStorage.plugin]
})
