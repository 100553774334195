import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import en from '@/lang/en.json'
import ro from '@/lang/ro.json'
import VueI18n from 'vue-i18n'
import Buefy from 'buefy'

Vue.use(Buefy, {
	defaultIconPack: 'mdi'
})

Vue.use(VueI18n)

const messages = Object.assign({en, ro})

const i18n = new VueI18n({
	locale: 'ro',
	fallbackLocale: 'en',
	messages
});

Vue.prototype.$scrollToTop = () => window.scroll({
	top: 0,
	left: 0,
	behavior: 'smooth'
})

Vue.config.productionTip = false

new Vue({
	router,
	i18n,
	store,
	render: h => h(App)
}).$mount('#app')
